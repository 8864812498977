import Header from "../Header";
import Footer from "../Footer";
import TopBtn from "../TopBtn";
import "./index.css";
const Contact = () => {
  return (
    <>
      <div className="contact-bg">
        <Header />
        <TopBtn />
        <div className="contact-content">
          <h2 className="contact-heading">
            Get In Touch With Us Via Contact Form
          </h2>
          <form>
            <div className="form-details">
              <div className="fields">
                <label className="contact-label">Name:</label>
                <input
                  type="text"
                  className="input-feild"
                  placeholder="Enter Your Name"
                  required
                />
              </div>
              <div className="fields">
                <label className="contact-label">Email:</label>
                <input
                  type="email"
                  className="input-feild"
                  placeholder="example@gmail.com"
                  required
                />
              </div>
              <div className="fields">
                <label className="contact-label">Message:</label>
                <textarea
                  className="text-area"
                  placeholder="Your Message..."
                  name="w3review"
                  rows={6}
                  cols={40}
                ></textarea>
              </div>
            </div>
            <button type="submit" className="contact-submit-btn">
              Submit Form
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Contact;
