import { useRef } from "react";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { AiOutlineMenuUnfold } from "react-icons/ai";

import "./index.css";
const Header = () => {
  const navRef = useRef();
  const showNavBar = () => {
    navRef.current.classList.toggle("responsive-nav");
  };
  return (
    <header>
      <h3>
        <Link to="/" className="site-title">
          Advibehub
        </Link>
      </h3>
      <div>
        <nav ref={navRef}>
          <Link to="/" className="links">
            Home
          </Link>
          <Link to="/services" className="links">
            Services
          </Link>
          <Link to="/about-us" className="links">
            About
          </Link>
          <Link to="/contact-us" className="links">
            Contact
          </Link>
          <button className="nav-btn close-nav-btn" onClick={showNavBar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavBar}>
          <AiOutlineMenuUnfold />
        </button>
      </div>
    </header>
  );
};
export default Header;
