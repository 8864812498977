import styled from "styled-components";

export const Button = styled.div`
  position: fixed;
  right: 10px;
  bottom: 70px;
  height: 20px;
  font-size: 42px;
  z-index: 1;
  cursor: pointer;
  color: #b927cc;
`;
