import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import TopBtn from "../TopBtn";
import { BsBadgeAd } from "react-icons/bs";
import { ImYoutube2 } from "react-icons/im";
import { IoLogoWordpress } from "react-icons/io5";
import { TbSeo } from "react-icons/tb";

import "./index.css";

const Services = () => {
  return (
    <>
      <div className="services-bg">
        <Header />
        <TopBtn />
        <div className="services-div">
          <div className="services-card">
            <BsBadgeAd className="service-icon" />
            <h3 className="service-title">Facebook Ads</h3>
            <Link to="/fbads">
              <button type="button" className="service-btn">
                Know More
              </button>
            </Link>
          </div>
          <div className="services-card">
            <ImYoutube2 className="service-icon" />
            <h3 className="service-title">Youtube Ads</h3>
            <button type="button" className="service-btn">
              Know More
            </button>
          </div>
          <div className="services-card">
            <IoLogoWordpress className="service-icon" />
            <h3 className="service-title">Web Development</h3>
            <button type="button" className="service-btn">
              Know More
            </button>
          </div>
          <div className="services-card">
            <TbSeo className="service-icon" />
            <h3 className="service-title">SEO</h3>
            <button type="button" className="service-btn">
              Know More
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Services;
