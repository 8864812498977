import Header from "../Header";
import Footer from "../Footer";
import TopBtn from "../TopBtn";
import { TbSeo } from "react-icons/tb";
import { DiWordpress } from "react-icons/di";
import { SiBrandfolder } from "react-icons/si";

import "./index.css";
const Home = () => {
  return (
    <>
      <div className="first-sec">
        <Header />
        <div className="first-sec-content">
          <h1 className="first-sec-heading">
            Digital Marketing
            <span className="agency-text"> Agency</span>
          </h1>
          <p className="first-sec-desc">
            We offer the best digital solutions for your business
          </p>
          <button className="join-now-btn">join now</button>
        </div>
      </div>
      <div className="second-sec">
        <div className="div-1">
          <h1 className="what-we-offer">What we offer?</h1>
          <p className="what-we-offer-desc">
            Website is a collection of web pages from a domain or sub domain
            that contains various information that can be accessed via the
            internet. Website is used as a medium to find various information
            and means of communication.
          </p>
        </div>
        <div className="div-2">
          <div className="service">
            <DiWordpress className="service-image" />
            <h3 className="service-name">Web Desiging</h3>
            <p className="service-desc">
              Web design is the process of crafting websites, focusing on
              aesthetics, usability, and functionality. It involves layout
              design, color schemes, typography, and interactive elements to
              engage users effectively.
            </p>
          </div>
          <div className="service">
            <TbSeo className="service-image" />
            <h3 className="service-name">SEO</h3>
            <p className="service-desc">
              SEO (Search Engine Optimization) enhances a website's visibility
              and ranking on search engine results pages through strategies like
              keyword optimization, quality content creation, and link building,
              ultimately driving organic traffic.
            </p>
          </div>
          <div className="service">
            <SiBrandfolder className="service-image" />
            <h3 className="service-name">Branding</h3>
            <p className="service-desc">
              Branding is the process of creating a unique identity and
              perception for a product, service, or company. It involves
              defining values, messaging, visuals, and experiences to establish
              recognition and connection with audiences.
            </p>
          </div>
        </div>
      </div>
      <div className="third-sec">
        <div className="third-sec-1">
          <h2 className="third-sec-heading">Why Should You Choose Us ?</h2>
          <img
            src="https://advibehub.online/images/why-choose.webp"
            className="why-choose-img-sm"
            alt="why-choose-img"
          />
          <p className="third-sec-desc">
            Website is a collection of web pages from a domain or sub domain
            that contains various information that can be accessed via the
            internet. Website is used as a medium to find various information
            and means of communication. Someone will visit a website because
            they are interested in the content available on the website.
          </p>
        </div>
        <img
          src="https://advibehub.online/images/why-choose.webp"
          className="why-choose-img-lg"
          alt="why-choose-img"
        />
      </div>
      <TopBtn />
      <Footer />
    </>
  );
};
export default Home;
